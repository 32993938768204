import React from "react";

import elegir_img_x from "../../images/elegir_x.svg"
import elegir_img_y from "../../images/elegir_b-01.svg";
import confirmar_img from "../../images/confirmar respuestas_b-01.svg";
import {getMessage} from "../../util";
import {PropsComoVotar} from "../../aux/PropsComoVotar";
import elegir_metodo from "../../images/elegir_metodo_run_serial_password_clave_unica.svg";
import depositar_voto from "../../images/depositar_voto.svg";

const RunSerialPasswordAutentizClaveUnicaNone = (props: PropsComoVotar) => {
    const elegir_img = (props.configuration.square_image ? elegir_img_x : elegir_img_y);
    return(
        <div className="row mb-4">
            <div className="col-lg col-sm-6 col-md-4 text-center">
                <p className="como-title mt-4">1. {getMessage("paso_elegir", props.messages)}</p>
                <p className="font-italic h5 vp-75">{getMessage("instruccion_elegir", props.messages)}</p>
                <img src={elegir_img} className="img-max-300" alt="Cómo"/>
            </div>

            <div className="col-lg col-sm-6 col-md-4 text-center border-left">
                <p className="como-title mt-4">2. {getMessage("paso_confirmar", props.messages)}</p>
                <p className="font-italic h5 vp-75">{getMessage("instruccion_confirmar", props.messages)}</p>
                <img src={confirmar_img} className="img-max-300" alt="Confirmar"/>
            </div>

            <div className="col-lg col-sm-6 col-md-4 text-center border-left">
                <p className="como-title mt-4">3. {getMessage("paso_confirmar_multi_autenticacion", props.messages)}</p>
                <p className="font-italic h5 vp-75">{getMessage("elegir_metodo_autenticacion", props.messages)}</p>
                <img src={elegir_metodo} className="img-max-300" alt="Votar"/>
            </div>

            <div className="col-lg col-sm-6 col-md-4 text-center border-left">
                <p className="como-title mt-4">4. {getMessage("paso_votar", props.messages)}</p>
                <p className="font-italic h5 vp-75">{getMessage("instruccion_votar_multi_autenticacion", props.messages)}</p>
                <img src={depositar_voto} className="img-max-300" alt="Votar"/>
            </div>




        </div>
    )
}

export default RunSerialPasswordAutentizClaveUnicaNone