import React from "react";
import ojo_img from "./images/ojo.svg";
import cuaderno_img from "./images/cuaderno.svg";
import {getMessage} from "./util";

const SaberMas =(props)=> {

        return (
            <div className="row justify-content-center sabermas EvColorBlue text-white">
                <div className="col-12 col-md-6 text-center mt-4 sabermas-box">
                    <h3 className="font-weight-bold">{getMessage("saber_mas_titulo_1", props.messages)}
                        <br/>{getMessage("saber_mas_titulo_2", props.messages)}
                        <br/>{getMessage("saber_mas_titulo_3", props.messages)}</h3>
      
                    <div><span className="linespanwhite mb-4"></span></div>
                </div>
                <div className="col-12 col-md-6 text-center h5 ">
                    <div className="row">
                        <div className="col-6">
                            <a href={props.election.configuration.bulletin_url.replace(/%s/g, props.election._id)}>
                                <img src={ojo_img} className="img-max-100" alt="Participación"/>
                                <p className="font-weight-bold no-decoration">{getMessage("participacion_1", props.messages)}
                                <br/> {getMessage("participacion_2", props.messages)} </p>
                            </a>
                        </div>
                        <div className="col-6">
                            <a href={props.election.configuration.ballot_box_url + '/election_comments/' + props.election._id + '/new'}>
                                <img src={cuaderno_img} className="img-max-100" alt="Registrar Observación"/>
                                <p className="font-weight-bold no-decoration">{getMessage("observacion_1", props.messages)}
                                <br/> {getMessage("observacion_2", props.messages)}
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        );
    }


export default SaberMas;